<!-- Duration Input -->
<!-- Component that allow user to input a duration (hours, minutes, seconds) with differents fields -->
<template>
  <div class="DurationInput">
    <b-field grouped>
      <b-field v-if="showHours">
        <b-input type="number" v-model.number="hours"/> <span class="control">h</span>
      </b-field>
      <b-field v-if="showMinutes">
        <b-input type="number" v-model.number="minutes"/> <span class="control">min</span>
      </b-field>
      <b-field v-if="showSeconds">
        <b-input type="number" v-model.number="seconds"/> <span class="control">sec</span>
      </b-field>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'DurationInput',
  props: {
    value: { type: Number },
    showHours: { type: Boolean, default: false},
    showMinutes: { type: Boolean, default: true },
    showSeconds: { type: Boolean, default: true },
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  methods: {
    updateValue(){
      this.$emit('input', this.hours*3600 + this.minutes*60 + this.seconds);
    }
  },
  watch: {
    value(){
      this.hours = this.showHours ? Math.floor(this.value/3600) : 0;
      this.minutes = this.showMinutes ?
        (this.showHours ? Math.floor((this.value%3600)/60) : Math.floor(this.value/60))
        : 0;
      this.seconds = this.showSeconds ?
        (this.showMinutes ? this.value%60 : this.value)
        : 0;
    },
    hours(){
      this.updateValue();
    },
    minutes(){
      this.updateValue();
    },
    seconds(){
      this.updateValue();
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
