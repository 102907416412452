<template>
  <div class="ftpestimator">
    <h2 class="title is-4">{{ $t('tools.ftp_estimator') }}</h2>
    <h4 class="title is-6">{{ $t('tools.best_short_effort') }}</h4>
    <b-field>
      <b-input v-model.number="power1" type="number" step="1"/><span class="control">W</span>
    </b-field>
    <DurationInput v-model.number="time1"/>
    <h4 class="title is-6">{{ $t('tools.best_long_effort') }}</h4>
    <b-field>
      <b-input v-model.number="power2" type="number" step="1"/><span class="control">W</span>
    </b-field>
    <DurationInput v-model.number="time2"/>
    <p>{{ $t('tools.estimated_ftp') }}: {{ ftp }}W  {{ $t('tools.estimated_anaerobic_capacity')}}: {{ Math.round(ac/100)/10 }}Kj</p>
  </div>
</template>

<script>
import DurationInput from '@/components/DurationInput.vue';

export default {
  name: 'ftpestimator',
  data() {
    return {
      power1: 0,
      time1: 0,
      power2: 0,
      time2: 0,
      ftp: 0,
      ac: 0,
    };
  },
  methods: {
    calc(){
      if(this.time1 && this.time2 && this.power1 && this.power2){
        this.ac = Math.round((this.time1*this.power1-this.time1*this.power2)/(1-this.time1/this.time2));
        this.ftp = Math.round(this.power2-this.ac/this.time2);
      }
    }
  },
  watch: {
    $data: {
      handler: function(){
        this.calc();
      },
      deep: true
    }
  },
  components: {
    DurationInput
  }
}
</script>

<style scoped>
.ftpestimator{
  background-color: white;
  width: 80%;
  text-align: center;
}
</style>
