<template>
  <div class="tools">
    <div class="box container">
      <div v-show="!currentTool" class="tools-link">
        <ul>
          <li><router-link to="/tools/ftp_estimator">{{ $t('tools.ftp_estimator') }}</router-link></li>
        </ul>
      </div>
      <FtpEstimator v-show="currentTool === 'ftp_estimator'"/>
    </div>

  </div>
</template>

<script>
import FtpEstimator from './FtpEstimator';

export default {
  name: 'tools',
  data() {
    return {
      currentTool: null
    };
  },
  methods: {
    routeUpdate(){
      var tool = this.$route.path.split('/').pop();
      switch(tool){
        case 'ftp_estimator':
          this.currentTool = 'ftp_estimator';
          break;
        default:
          this.currentTool = null;
          break;
      }
    }
  },
  mounted(){
    this.routeUpdate();
  },
  watch: {
    $route(to, from){
      this.routeUpdate();
    }
  },
  components: {
    FtpEstimator
  }
}
</script>

<style lang="scss">
</style>
